import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  

// 获取列表	/mweb/dic/getList
// 获取对应值	/mweb/dic/getOne
// 新增	/mweb/dic/add
// 修改	/mweb/dic/update
// 删除	/mweb/dic/delete

export const getDataDicList = params =>{
    let pm = qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/dic/getList`,pm);
}

export const getDataDicLisOne = params =>{
    let pm = qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/dic/getOne`,pm);
}
export const addDataDic = params =>{
    let pm = qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/dic/add`,pm);
}

export const updateDataDic = params =>{
    let pm = qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/dic/update`,pm);
}
export const deleteDataDic = params =>{
    let pm = qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/dic/delete`,pm);
}
export const getDicTree = params => {
    let pm = qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/dic/loadDicTree`,pm)
}